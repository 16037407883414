import React from "react";

import NoResultsIcon from "@/assets/images/icons/no-results.svg?react";
import { useTranslation } from "react-i18next";
import { StyledNoResultsContainer, StyledNoResultsContents, StyledNoResultsDivider, StyledNoResultsPrompt } from "@/components/Styles/PagesComponentsCommon";

// TODO: not at all convinced with below logic, find a better way
const BPNoResults = ({ isEsoSearchEnabled, isDunsMarket }) => {
  const { t } = useTranslation()
  return (
    <StyledNoResultsContainer>
      <StyledNoResultsContents>
        <NoResultsIcon aria-label="no results" />
        {isDunsMarket ? (
          <StyledNoResultsPrompt variant="body2" data-testid="duns-messages">
            {isEsoSearchEnabled ? t("businessPartnersPage.bpNoResults.esoMessage") : t("businessPartnersPage.bpNoResults.dunsMessage")}
          </StyledNoResultsPrompt>)
          :
          (<StyledNoResultsPrompt variant="body2" data-testid="eso-message">
            {t("businessPartnersPage.bpNoResults.createMessage")}
          </StyledNoResultsPrompt>)
        }

        <StyledNoResultsDivider />
      </StyledNoResultsContents>
    </StyledNoResultsContainer>
  );
};

export default BPNoResults;
