import React from "react";
import ConditionalLink from "@/components/ConditionalLink";
import {
  ACCOUNT_LOST,
  CUSTOMER,
  DOWNGRADED,
  LAPSED,
  LEAD,
  OTHER,
  PROSPECT,
  SUSPECT,
  BusinessPartner_ProspectStatus_Config,
  BusinessPartner_InEvaluationStatus_Config,
  BusinessPartner_Lead_Config,
  BusinessPartner_CustomerStatus_Config,
  BusinessPartner_DowngradedStatus_Config,
  BusinessPartner_LapsedStatus_Config,
  BusinessPartner_AccountLostStatus_Config,
  BusinessPartner_OtherStatus_Config,
  VIEW,
  LEVEL3_VALUE,
  BusinessPartner_ComplianceCheck_ErrorMessageConfig,
  NKAM_BusinessPartner_Missing_GLOBUSId_Message,
  BusinessPartner_ComplianceCheck_LeadCheckConfig,
  NKAM_Globus_Status_Lock,
  Contact_ComplianceChecks_LeadCheck,
  NATIONAL_ID1_LABEL,
  NATIONAL_ID2_LABEL,
  NATIONAL_ID3_LABEL,
  FIELD_REQUIRED,
  FIELD_NOT_REQUIRED,
  LEAD_VALUE,
  LAPSED_VALUE,
  PROSPECT_VALUE,
  ACCOUNT_LOST_VALUE,
  SUSPECT_VALUE,
  ACCOUNT_LOST_REQUIRED,
  CUSTOMER_REQUIRED,
  DOWNGRADED_REQUIRED,
  LEAD_REQUIRED,
  SUSPECT_REQUIRED,
  PROSPECT_REQUIRED,
  LAPSED_REQUIRED,
  CUSTOMER_VALUE,
  DOWNGRADED_VALUE,
  LEVEL2_VALUE,
} from "@/constants/constants";
import { BusinePartnerEntity } from "@/constants/forms";
import apiBPData from "@/services/apiBusinessPartners";
import apiRetailersData from "@/services/apiRetailers";
import apiUIData from "@/services/apiUI";
import utils from "@/utils/common";
import { isTypeRestrictedInPortal } from "./generic";
import { useTranslation } from "react-i18next";

export const filterBusinessPartnerData = (businessPartners) => {
  const filteredL2Bps = businessPartners.filter(
    ({ companyHierarchy }) => companyHierarchy?.value === LEVEL2_VALUE,
  );

  const filteredL3Bps = businessPartners.filter(
    ({ companyHierarchy }) => companyHierarchy?.value === LEVEL3_VALUE,
  );

  return {
    l2Count: filteredL2Bps?.length,
    l3Count: filteredL3Bps?.length,
  };
};

export const useBusinessPartnersTableColumns = () => {
  const { t } = useTranslation();
  return [
    {
      field: "BusinessPartnerName",
      headerName: t("businessPartnersPage.datagridColumns.businessPartnerName"),
      type: "string",
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.name;
      },
      renderCell: (params) => {
        return (
          <ConditionalLink
            title={params.row.name}
            url={params.row.businessPartnerRoute + params.row.id}
          />
        );
      },
    },
    {
      field: "nkamStatus",
      headerName: t("businessPartnersPage.datagridColumns.nkamStatus"),
      type: "string",
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.nkamStatus.name;
      },
      renderCell: (params) => {
        return params.row.nkamStatus?.name;
      },
    },
    {
      field: "type",
      headerName: t("businessPartnersPage.datagridColumns.type"),
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.type?.name;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.type?.name, 15);
      },
    },
    {
      field: "owningKAM",
      headerName: t("businessPartnersPage.datagridColumns.owningKAM"),
      headerClass: "custom-header",
      flex: 2,
      maxWidth: "200px",
      valueGetter: (params) => {
        return params.row.owningKAM?.name;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.owningKAM?.name, 45);
      },
    },
    {
      field: "postcode",
      headerName: t("businessPartnersPage.datagridColumns.postcode"),
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.address1PostCode;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.address1PostCode, 7);
      },
    },
    {
      field: "city",
      headerName: t("businessPartnersPage.datagridColumns.city"),
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.address1City;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.address1City, 25);
      },
    },
    {
      field: "vatNumber",
      headerName: t("businessPartnersPage.datagridColumns.vatNumber"),
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.vatNumber;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.vatNumber, 15);
      },
    },
    {
      field: "nationalId1",
      headerName: t("businessPartnersPage.datagridColumns.nationalID1"),
      headerClass: "custom-header",
      flex: 1,
      maxWidth: "100px",
      valueGetter: (params) => {
        return params.row.nationalId1;
      },
      renderCell: (params) => {
        return utils.trimAndEllipsis(params.row.nationalId1, 10);
      },
    },
  ];
};

export const getNkamStatusConfig = (nkamStatus) => {
  const configMap = {
    [PROSPECT]: BusinessPartner_ProspectStatus_Config,
    [LEAD]: BusinessPartner_Lead_Config,
    [CUSTOMER]: BusinessPartner_CustomerStatus_Config,
    [LAPSED]: BusinessPartner_LapsedStatus_Config,
    [ACCOUNT_LOST]: BusinessPartner_AccountLostStatus_Config,
    [DOWNGRADED]: BusinessPartner_DowngradedStatus_Config,
    [SUSPECT]: BusinessPartner_InEvaluationStatus_Config,
    [OTHER]: BusinessPartner_OtherStatus_Config,
  };
  return configMap[nkamStatus] || configMap[OTHER];
};

export const shouldPrintConfirmationButtonVisibile = (
  mode,
  printConfirmationTemplatesExists,
  restrictConfirmationTemplates,
) => {
  //set print confirmation button on or off
  //If NOT view mode then it is false
  //If it IS view mode then
  //show if printconfirmationtemplates != null && restrictConfirmationTemplates == false
  if (mode === VIEW) {
    if (restrictConfirmationTemplates) {
      return false;
    } else {
      //print confirmation is allowed, show btns if templates are available
      return Boolean(printConfirmationTemplatesExists);
    }
  } else {
    //not VIEW mode, hide button
    return false;
  }
};

// Functionality to fetch owning retailers data and returning populated retailer name with retailer number
export const setOwningRetailerOptionsWithDealerNumbers = async (
  owningRetailers,
  controller,
) => {
  const owningRetailersWithRetailerNumbers = await Promise.all(
    owningRetailers.map(async ({ id, name, logicalName }) => {
      const retailerId = await apiRetailersData.getRetailerById(id, controller);

      return {
        id,
        logicalName,
        name: name + " - " + retailerId.data?.result?.retailerNumber,
        originalName: name,
      };
    }),
  );

  return new Promise((resolve) => {
    resolve(owningRetailersWithRetailerNumbers);
  });
};

export const setOwningRetailerWithRetailerId = async (
  owningRetailer,
  owningRetailerOptions,
  controller,
) => {
  // Update for NKAM-17958
  // First check if the owning retailer alredy present options.
  // If found then update the same retailer number to the default owning retailer.
  // If not found then make a call to get the retailer.
  // This way we avoid an unnecessary API call.
  if (!owningRetailer) {
    return Promise.resolve(null);
  }
  const { id, logicalName, name } = owningRetailer;
  if (owningRetailerOptions) {
    const retailerFound = owningRetailerOptions.find(
      (retailer) => retailer.id === id,
    );
    if (retailerFound) {
      return Promise.resolve({
        id,
        logicalName,
        name: `${retailerFound.name}`,
        originalName: name,
      });
    }
  }
  try {
    const response = await apiRetailersData.getRetailerById(id, controller);
    return Promise.resolve({
      id,
      logicalName,
      name: `${name} - ${response.data?.result?.retailerNumber}`,
      originalName: name,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setPrimaryAndNonPrimaryContacts = (contacts, primaryContactId) => {
  // Checks the primary Contact IDs assigned to the BP,
  // against the contacts related.
  let primaryContacts = [];
  if (primaryContactId) {
    primaryContacts = contacts.filter((item) => item.id === primaryContactId);
  }
  const nonPrimaryContacts = contacts.filter(
    (item) => item.id !== primaryContactId,
  );

  return {
    primaryContacts,
    nonPrimaryContacts,
  };
};

export const getVerifiedPermissions = (
  permissions,
  canMarketEditABP,
  businessPartner,
  typeOptions
) => {
  const {
    agencyModelRestricted,
    retailerGroupIsDesignatedRetailer,
    retailerGroupIsOwningRetailer,
    designatedRetailer,
    owningRetailer,
  } = permissions;

  let isPermittedToEditBp = false;
  let isPrimaryContactsVisible = false;
  let isContractsOrDesignatedRetailersVisible = false;
  const isNonPrimaryContactVisible = retailerGroupIsOwningRetailer;
  let typeRestricted = isTypeRestrictedInPortal(businessPartner?.type, typeOptions);

  // Set permission to edit Businesss Partner.
  if (canMarketEditABP) {
    if ((
      (!agencyModelRestricted &&
        (retailerGroupIsOwningRetailer || retailerGroupIsDesignatedRetailer)) ||
      (agencyModelRestricted && retailerGroupIsOwningRetailer)) && !typeRestricted
    ) {
      isPermittedToEditBp = true;
    }
  }
  // Set visibility of contracts and designated retailers. Both share same logic
  if (
    !agencyModelRestricted ||
    (agencyModelRestricted &&
      (owningRetailer ||
        retailerGroupIsOwningRetailer ||
        designatedRetailer ||
        retailerGroupIsDesignatedRetailer)) ||
    (agencyModelRestricted &&
      !owningRetailer &&
      !retailerGroupIsOwningRetailer &&
      !designatedRetailer &&
      !retailerGroupIsDesignatedRetailer &&
      businessPartner.totalNumberOfActiveRetailerRoles === 0 &&
      businessPartner.owningRetailer) ||
    (agencyModelRestricted &&
      !owningRetailer &&
      !retailerGroupIsOwningRetailer &&
      !designatedRetailer &&
      !retailerGroupIsDesignatedRetailer &&
      businessPartner.totalNumberOfActiveRetailerRoles === 0 &&
      businessPartner.owningRetailer === undefined)
  ) {
    isContractsOrDesignatedRetailersVisible = true;
  }
  //Sets visibility according to BMW business defined cntractual rules so that
  //only certain dealers, or delaer groups, can see certain information
  if (
    retailerGroupIsOwningRetailer ||
    (retailerGroupIsDesignatedRetailer &&
      !retailerGroupIsOwningRetailer &&
      businessPartner.owningRetailer) ||
    (retailerGroupIsDesignatedRetailer && !businessPartner.owningRetailer)
  ) {
    isPrimaryContactsVisible = true;
  }

  return {
    isPermittedToEditBp: isPermittedToEditBp,
    isPrimaryContactsVisible: isPrimaryContactsVisible,
    isContractsOrDesignatedRetailersVisible:
      isContractsOrDesignatedRetailersVisible,
    isNonPrimaryContactVisible: isNonPrimaryContactVisible,
  };
};

export const getTermsOfCreditOption = async (
  accountType,
  companyHierarchy,
  l2ParentCompany,
  controller,
) => {
  try {
    let businessPartnerId = "";
    let selectedTerms = null;
    let termOfCreditOptions = [];

    if (companyHierarchy === LEVEL3_VALUE) {
      businessPartnerId = l2ParentCompany?.id;
    }

    if (businessPartnerId) {
      const response = await apiBPData.searchtermsofcreditBybusinesspartnerid(
        businessPartnerId,
        controller,
      );

      termOfCreditOptions = response?.data?.result ? response?.data?.result.map(
        ({ name, id }) => ({
          name,
          value: id + "",
        }),
      ) : [];

      selectedTerms = response?.data?.result?.find(
        (x) => x.isSelected === true,
      );
    }

    if (!selectedTerms?.id && accountType) {
      const response = await apiBPData.searchtermsofcreditBytype(
        accountType,
        controller,
      );

      termOfCreditOptions = response?.data?.result ? response?.data?.result.map(
        ({ name, id }) => ({
          name,
          value: id,
        }),
      ) : [];
    }

    return Promise.resolve({
      termOfCreditOptions,
      selectedTerms,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// Below code will be depricated
export const setComplyLeadCheckErrorMessage = async (
  businessPartner,
  nonCompliantContacts,
) => {
  let complyMessage = "";

  try {
    const [
      contactComplyConfigRes,
      globusStatusLockRes,
      bpComplyConfigRes,
      globusMissingMessage,
      bpComplyErrorMessage,
    ] = await Promise.all([
      apiUIData.getCrmConfiguration(Contact_ComplianceChecks_LeadCheck),
      apiUIData.getCrmConfiguration(NKAM_Globus_Status_Lock),
      apiUIData.getCrmConfiguration(
        BusinessPartner_ComplianceCheck_LeadCheckConfig,
      ),
      apiUIData.getCrmConfiguration(
        NKAM_BusinessPartner_Missing_GLOBUSId_Message,
      ),
      apiUIData.getCrmConfiguration(
        BusinessPartner_ComplianceCheck_ErrorMessageConfig,
      ),
    ]);

    const {
      booleanValue: contactComplyBoolean,
      booleanValue: globusStatusBoolean,
      booleanValue: bpComplyBoolean,
    } = {
      ...contactComplyConfigRes.data?.result || {},
      ...globusStatusLockRes.data?.result || {},
      ...bpComplyConfigRes.data?.result || {},
    };

    if (globusStatusBoolean && !businessPartner?.globusId) {
      complyMessage = globusMissingMessage?.data?.result?.description;
    } else if (contactComplyBoolean || bpComplyBoolean) {
      if (
        (nonCompliantContacts !== undefined && nonCompliantContacts !== null) ||
        !businessPartner?.greyMarketCheckPerformed ||
        !businessPartner?.coinCheckPerformed ||
        businessPartner?.greyMarketCheckResult?.value != 174640002 ||
        businessPartner?.coinCheckResult?.value != 174640002
      ) {
        complyMessage = bpComplyErrorMessage?.data?.result?.stringValue;
      }
    }
    return Promise.resolve(complyMessage);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getComplianceErrorMessage = (
  businessPartner,
  nonCompliantContacts,
  {
    contactComplyBoolean,
    globusStatusBoolean,
    bpComplyBoolean,
    globusMissMsg,
    bpComplyErrorMessage,
  } = complyObj,
) => {
  let complyMessage = "";

  if (globusStatusBoolean && !businessPartner?.globusId) {
    complyMessage = globusMissMsg;
  } else if (contactComplyBoolean || bpComplyBoolean) {
    if (
      (nonCompliantContacts !== undefined && nonCompliantContacts !== null) ||
      !businessPartner?.greyMarketCheckPerformed ||
      !businessPartner?.coinCheckPerformed ||
      businessPartner?.greyMarketCheckResult?.value != 174640002 ||
      businessPartner?.coinCheckResult?.value != 174640002
    ) {
      complyMessage = bpComplyErrorMessage;
    }
  }

  return complyMessage;
};

export const getFieldNameForError = (err) => {
  var fieldname = "";
  if (err.fieldName === "bmw_vatnumber") {
    fieldname = "vatNumber";
  } else if (err.fieldName === "bmw_nsccompanyid") {
    fieldname = "nationalId1";
  } else if (err.fieldName === "bmw_nsccompanyid2") {
    fieldname = "nationalId2";
  } else if (err.fieldName === "bmw_nsccompanyid3") {
    fieldname = "nationalId3";
  } else if (err.fieldName === "address1_postalcode") {
    fieldname = "address1PostCode";
  } else if (err.fieldName === "address2_postalcode") {
    fieldname = "address2PostCode";
  } else {
    fieldname = "";
  }

  return fieldname;
};

export const getNationalIdLabels = async (controller) => {
  try {
    let [nat1Response, nat2Response, nat3Response] = await Promise.all([
      apiUIData.getCrmConfiguration(NATIONAL_ID1_LABEL, controller),
      apiUIData.getCrmConfiguration(NATIONAL_ID2_LABEL, controller),
      apiUIData.getCrmConfiguration(NATIONAL_ID3_LABEL, controller),
    ]);

    const { description: NationalId1Label, booleanValue: Nat1Boolean } =
      nat1Response?.data?.result || {};
    const { description: NationalId2Label, booleanValue: Nat2Boolean } =
      nat2Response?.data?.result || {};
    const { description: NationalId3Label, booleanValue: Nat3Boolean } =
      nat3Response?.data?.result || {};

    return Promise.resolve({
      NationalId1Label: (Nat1Boolean && NationalId1Label) || "National ID 1",
      NationalId2Label: (Nat2Boolean && NationalId2Label) || "National ID 2",
      NationalId3Label: (Nat3Boolean && NationalId3Label) || "National ID 3",
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getRequiredBasedOnLegalEntityType = (
  requiredFields,
  legalEntityType,
  marketCode,
) => {
  const requiredBasedOnLegalEntityType = {
    isVatRequired: requiredFields.some(
      (field) =>
        field.SchemaName === "bmw_vatnumber" &&
        (field.Markets?.includes(marketCode) || field.Markets?.length === 0),
    ),
    isNationalID1Required: requiredFields.some(
      (field) =>
        field.SchemaName === "bmw_nsccompanyid" &&
        (field.Markets?.includes(marketCode) || field.Markets?.length === 0),
    ),
    isNationalID2Required: requiredFields.some(
      (field) =>
        field.SchemaName === "bmw_nsccompanyid2" &&
        (field.Markets?.includes(marketCode) || field.Markets?.length === 0),
    ),
    isNationalID3Required: requiredFields.some(
      (field) =>
        field.SchemaName === "bmw_nsccompanyid3" &&
        (field.Markets?.includes(marketCode) || field.Markets?.length === 0),
    ),
  };

  if (legalEntityType && legalEntityType.vatBusinessPartnerRequried) {
    requiredBasedOnLegalEntityType.isVatRequired =
      legalEntityType.vatBusinessPartnerRequried === FIELD_REQUIRED
        ? true
        : legalEntityType.vatBusinessPartnerRequried === FIELD_NOT_REQUIRED
          ? false
          : requiredBasedOnLegalEntityType.isVatRequired;
  }

  if (legalEntityType && legalEntityType.nationalId1BusinessPartnerRequried) {
    requiredBasedOnLegalEntityType.isNationalID1Required =
      legalEntityType.nationalId1BusinessPartnerRequried === FIELD_REQUIRED
        ? true
        : legalEntityType.nationalId1BusinessPartnerRequried ===
          FIELD_NOT_REQUIRED
          ? false
          : requiredBasedOnLegalEntityType.isNationalID1Required;
  }

  if (legalEntityType && legalEntityType.nationalId2BusinessPartnerRequried) {
    requiredBasedOnLegalEntityType.isNationalID2Required =
      legalEntityType.nationalId2BusinessPartnerRequried === FIELD_REQUIRED
        ? true
        : legalEntityType.nationalId2BusinessPartnerRequried ===
          FIELD_NOT_REQUIRED
          ? false
          : requiredBasedOnLegalEntityType.isNationalID2Required;
  }

  if (legalEntityType && legalEntityType.nationalId3BusinessPartnerRequried) {
    requiredBasedOnLegalEntityType.isNationalID3Required =
      legalEntityType.nationalId3BusinessPartnerRequried === FIELD_REQUIRED
        ? true
        : legalEntityType.nationalId3BusinessPartnerRequried ===
          FIELD_NOT_REQUIRED
          ? false
          : requiredBasedOnLegalEntityType.isNationalID3Required;
  }

  return requiredBasedOnLegalEntityType;
};

export const getRequiredFieldsConfigNameFromNkamStatus = (nkamStatus) => {
  const map = {
    [LEAD_VALUE]: LEAD_REQUIRED,
    [ACCOUNT_LOST_VALUE]: ACCOUNT_LOST_REQUIRED,
    [CUSTOMER_VALUE]: CUSTOMER_REQUIRED,
    [DOWNGRADED_VALUE]: DOWNGRADED_REQUIRED,
    [SUSPECT_VALUE]: SUSPECT_REQUIRED,
    [PROSPECT_VALUE]: PROSPECT_REQUIRED,
    [LAPSED_VALUE]: LAPSED_REQUIRED,
  };

  return map[nkamStatus] || PROSPECT_REQUIRED;
};

export const filterRequiredFields = (
  configRequiredFields,
  address1Line1,
  postbox,
  address2Line1,
  address2PostBox,
  hasBillingAddress,
) => {
  let filteringReqFields = configRequiredFields || [];
  const isMainAddressStreetAndPostBoxMandatory =
    configRequiredFields.find(
      (schemaName) => schemaName === BusinePartnerEntity.address1Line1,
    ) &&
    configRequiredFields.find(
      (schemaName) => schemaName === BusinePartnerEntity.postbox,
    );
  const isBillingAddressStreetAndPostBoxMandatory =
    configRequiredFields.find(
      (schemaName) => schemaName === BusinePartnerEntity.address2Line1,
    ) &&
    configRequiredFields.find(
      (schemaName) => schemaName === BusinePartnerEntity.address2PostBox,
    );
  if (isMainAddressStreetAndPostBoxMandatory) {
    if (!address1Line1 && postbox) {
      filteringReqFields = filteringReqFields.filter(
        (schemaName) => schemaName !== BusinePartnerEntity.address1Line1,
      );
    } else if (address1Line1 && !postbox) {
      filteringReqFields = filteringReqFields.filter(
        (schemaName) => schemaName !== BusinePartnerEntity.postbox,
      );
    }
  }
  if (hasBillingAddress && isBillingAddressStreetAndPostBoxMandatory) {
    if (!address2Line1 && address2PostBox) {
      filteringReqFields = filteringReqFields.filter(
        (schemaName) => schemaName !== BusinePartnerEntity.address2Line1,
      );
    } else if (address2Line1 && !address2PostBox) {
      filteringReqFields = filteringReqFields.filter(
        (schemaName) => schemaName !== BusinePartnerEntity.address2PostBox,
      );
    }
  }
  return filteringReqFields;
};
