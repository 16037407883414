import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NoResultsIcon from "@/assets/images/icons/no-results.svg?react";
import { useTranslation } from "react-i18next";

export const NoResults = ({ isDunsMarket }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledContents>
        <NoResultsIcon />

        <StyledPrompt variant="body2">
          {isDunsMarket
            ? t("esoSearchPage.noResultsDUNS")
            : t("esoSearchPage.noResultsBP")}
        </StyledPrompt>

        <StyledDivider />
      </StyledContents>
    </StyledContainer>
  );
};
const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const StyledContents = styled(Box)({
  maxWidth: 225,
  textAlign: "center",
});
const StyledPrompt = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  marginTop: theme.spacing(1),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 1),
}));
