import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { createBusinessPartnerRoute } from "@/constants/routes";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Fab, styled } from "@mui/material";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import Backdrop from "@/components/Backdrop";
import BPNoResults from "@/components/BusinessPartner/NoResults";
import DUNSResultsList from "@/components/DUNS/DUNSResultsList";
import Alert from "@/components/Form/Alert";
import Loader from "@/components/Loader";
import { AppContext } from "@/context/AppContext";
import { BusinessPartnersContext } from "@/context/BusinessPartnersContext";
import {
  canMarketCreateBPKey,
  canMarketCreateBPViaDunsKey,
  WARNING,
} from "@/constants/constants";
import { businessPartnerRoute } from "@/constants/routes";
import {
  StyledHeading,
  StyledOrBox,
  StyledPageDescription,
  StyledPagedivider,
  StyledResultContainer,
} from "@/components/Styles/PagesComponentsCommon";
import { handleApiError } from "@/utils/handleApiError";
import { useDebounce } from "@/hooks/useDebounce";
import { useSearchDunsQuery } from "@/services/queries";
import { useCreateDunsBPMutation } from "@/services/mutations";
import { useTranslation } from "react-i18next";

export const DunsSearchPage = () => {
  const navigate = useNavigate();
  const controller = new AbortController();

  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    userData: {
      [canMarketCreateBPKey]: canMarketCreateABP,
      [canMarketCreateBPViaDunsKey]: canMarketCreateBPViaDuns,
    },
  } = useContext(AppContext);

  const [showAlert, setShowAlert] = useState(false);

  const {
    nameSearch,
    setNameSearch,
    citySearch,
    setCitySearch,
    postcodeSearch,
    setPostcodeSearch,
    companyCodesSearch,
    setCompanyCodesSearch,
  } = useContext(BusinessPartnersContext);

  const { t } = useTranslation();
  const [dunsBusinessPartners, setDunsBusinessPartners] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [apiError, setApiError] = useState({});

  const debouncedSearch = useDebounce([nameSearch, citySearch, postcodeSearch, companyCodesSearch]);

  const searchDunsQuery = useSearchDunsQuery(nameSearch, citySearch, postcodeSearch, companyCodesSearch, debouncedSearch);
  const createDunsBPMutation = useCreateDunsBPMutation();

  useEffect(() => {
    if (searchDunsQuery.isFetching) return
    if (searchDunsQuery.isError) {
      setApiError(handleApiError(searchDunsQuery.error));
    }
    if (searchDunsQuery.isSuccess) {
      setTotalRecords(searchDunsQuery.data?.data?.result?.data.length);
      setDunsBusinessPartners(searchDunsQuery.data?.data?.result?.data);
      setSearched(true);
    }
  }, [searchDunsQuery.dataUpdatedAt, searchDunsQuery.errorUpdatedAt]);

  useEffect(() => {
    setIsLoading(createDunsBPMutation.isPending);
    if (createDunsBPMutation.isError) {
      setApiError(handleApiError(createDunsBPMutation.error));
    }
    if (createDunsBPMutation.isSuccess) {
      if (createDunsBPMutation.data?.data?.result.created) {
        navigate(businessPartnerRoute + createDunsBPMutation.data?.data?.result?.createdId, {
          state: { createdFromDuns: createDunsBPMutation.data?.data?.result?.created },
        });
      } else {
        // If a business partner has been created already from D&B record, displays a warning.
        createAlert(WARNING, createDunsBPMutation.data?.data?.result?.responseMessage);
      }
    }
  }, [createDunsBPMutation.status])

  useEffect(() => {
    const handleSearchFormErrors = () => {
      if ((citySearch || postcodeSearch) && nameSearch) {
        setError(false);
      } else if ((citySearch || postcodeSearch) && !nameSearch) {
        setError(true);
      } else {
        setError(false);
      }
    };

    handleSearchFormErrors();
  }, [nameSearch, citySearch, postcodeSearch]);

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const createBusinessPartnerFromDuns = async (dunsId) => {
    setIsLoading(true);
    let payload = { dunsNumber: dunsId };

    createDunsBPMutation.mutate(payload);
  };

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      <ActionsBar />

      <Box mt={3}>
        <StyledHeading component="h2" variant="h5">
          {t("dunsSearchPage.heading")}
        </StyledHeading>

        <StyledPageDescription component="p" variant="body2">
          {t("dunsSearchPage.pageDesc")}
        </StyledPageDescription>
      </Box>

      <Box component="form" id="searchForm">
        <div className="searchFormRow">
          <div className="col-md">
            <TextField
              error={error}
              defaultValue={nameSearch}
              helperText={
                error
                  ? t("dunsSearchPage.companyErrorText")
                  : ""
              }
              label={t("dunsSearchPage.companyLabel")}
              onChange={(event) => setNameSearch(event.target.value)}
              type="search"
            />

            <TextField
              label={t("dunsSearchPage.cityLabel")}
              defaultValue={citySearch}
              onChange={(event) => setCitySearch(event.target.value)}
              type="search"
            />

            <TextField
              label={t("dunsSearchPage.postcodeLabel")}
              defaultValue={postcodeSearch}
              onChange={(event) => setPostcodeSearch(event.target.value)}
              type="search"
            />
          </div>

          <StyledOrBox
            display="flex"
            width={110}
            margin={1}
            borderRight="1px solid #8a8c8f"
            borderLeft="1px solid #8a8c8f"
            borderRadius={0}
          >
            <div className="fields-separator">{t("dunsSearchPage.or")}</div>
          </StyledOrBox>
          <div className="col-md align-self-center">
            <div>
              <TextField
                label={t("dunsSearchPage.companyNumberLabel")}
                onChange={(event) => setCompanyCodesSearch(event.target.value)}
                defaultValue={companyCodesSearch}
                type="search"
              />
            </div>
          </div>
        </div>
      </Box>

      <Box mt={3} display="flex" justifyContent="space-between">
        {(searched || isLoading || dunsBusinessPartners?.length > 0) && (
          <StyledHeading component="h2" variant="h5">
            {t("dunsSearchPage.searchResults")}
          </StyledHeading>
        )}

        {dunsBusinessPartners.length > 0 && (
          <Typography
            variant="body2"
            style={{ lineHeight: "1.5", textAlign: "right" }}
            m="auto"
          >
            {t("dunsSearchPage.showing")} {totalRecords} {t("dunsSearchPage.results")}
          </Typography>
        )}
      </Box>

      <StyledPagedivider />

      <StyledResultContainer>
        {searchDunsQuery?.isFetching && <Loader />}

        {dunsBusinessPartners.length === 0 ? (
          <>
            {searched && (
              <BPNoResults
                canMarketCreateBusinessPartner={canMarketCreateABP}
              />
            )}
          </>
        ) : (
          <>
            <DUNSResultsList
              data={dunsBusinessPartners}
              createBusinessPartnerFromDuns={createBusinessPartnerFromDuns}
              canMarketCreateBP={canMarketCreateABP}
              canMarketCreateBPViaDuns={canMarketCreateBPViaDuns}
            />
          </>
        )}
      </StyledResultContainer>

      {isLoading && <Backdrop />}
      {searched && (
        <MultiElementFloatingActionBar>
          <StyledOverrideMUIFabPositioningFab
            aria-label={t("formActions.createBP")}
            component={Link}
            disabled={!canMarketCreateABP}
            to={createBusinessPartnerRoute}
          >
            <AddIcon /> {t("formActions.createBP")}
          </StyledOverrideMUIFabPositioningFab>
        </MultiElementFloatingActionBar>
      )}
      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

const StyledOverrideMUIFabPositioningFab = styled(Fab)({
  right: "auto",
  position: "relative",
});
